// zE - the global var available after loading Zendesk in _zendesk.slim (where this pack is invoked)

/* eslint-disable no-undef */
window.zESettings = {
  webWidget: {
    color: {
      theme: '#4bc27d',
      launcherText: "#ffffff"
    }
  }
};

zE(function() {
  let ZENDESK_IDENTITY = window.ZENDESK_IDENTITY;
  if (ZENDESK_IDENTITY !== null && ZENDESK_IDENTITY.email !== '') {
    zE.identify({
      email: ZENDESK_IDENTITY.email,
      name: ZENDESK_IDENTITY.name,
      organization: ZENDESK_IDENTITY.organization,
    });
  }
});
/* eslint-enable no-undef */
